import Player from '@vimeo/player';

$(document).ready(()=>{
    let $section = $('section.homepage-video');
    $section.each((i,el)=>{
        let $iframe = $(el).find('iframe');
        let $cover = $(el).find('.homepage-video__cover');
        let $button = $(el).find('button');

        const player = new Player($iframe[0]);

        let isMuted = true;
        $button.on('click', function(){
            let $icon = $(this).find('.fa');
            let $text = $(this).find('.sr-only');
            $icon.removeClass('fa-volume-off fa-volume-up');
            if(isMuted){
                $icon.addClass('fa-volume-up');
                $text.text('Mute');
                isMuted = false;
            } else {
                $icon.addClass('fa-volume-off');
                $text.text('Unmute');
                isMuted = true;
            }
            player.setMuted(isMuted);
        });

        // if the cover version is being used this should help play the video
        $cover.on('click', function(){
            player.play();
            $cover.fadeOut();
        });
    });
});